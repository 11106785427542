import React, { useState, useEffect } from "react";
import Header from "../components/Header.js";
import { useAuth } from "../AuthContext.js";
import { useNavigate, useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Carousel } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import FreeSearchModal from "../pages/FreemiumSearch.js";


const LandingPage = ({ selectedLanguage, setSelectedLanguage }) => {
    const { currentUser, handleSignInOrRegister } = useAuth(); // Destructure the handleSignInOrRegister function from useAuth
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [activeBlog, setActiveBlog] = useState({});
    const [isFreeSearchModalOpen, setIsFreeSearchModalOpen] = useState(false);

    const intl = useIntl();

    const openFreeSearchModal = () => {
        setIsFreeSearchModalOpen(true);
    };

    const openModal = (blog) => {
        setActiveBlog(blog);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const slides = [
        { img: intl.formatMessage({ id: 'hero.image' }), alt: intl.formatMessage({ id: 'hero.alt' }) },
    ];


    useEffect(() => {
        if (currentUser) {
            navigate("/search");
        }
    }, [pathname, currentUser]);

    useEffect(() => {
        const next = (currentSlide + 1) % slides.length;
        const id = setTimeout(() => setCurrentSlide(next), 5000);  // Change image every 3 seconds
        return () => clearTimeout(id);
    }, [currentSlide]);

    const handleSearchButtonClick = () => {
        // Call handleSignInOrRegister when the button is clicked
        handleSignInOrRegister();
    };

    const blogs = [
        {
            "title": intl.formatMessage({ id: 'blog1.title' }),
            "snippet": intl.formatMessage({ id: 'blog1.snippet' }),
            "body": intl.formatMessage({ id: 'blog1.body' }),
            "image": "/images/blog0.webp",
            "readMoreLink": "#"
        },
        {
            "title": intl.formatMessage({ id: 'blog2.title' }),
            "snippet": intl.formatMessage({ id: 'blog2.snippet' }),
            "body": intl.formatMessage({ id: 'blog2.body' }),
            "image": "/images/blog1.webp",
            "readMoreLink": "#"
        },
        {
            "title": intl.formatMessage({ id: 'blog3.title' }),
            "snippet": intl.formatMessage({ id: 'blog3.snippet' }),
            "body": intl.formatMessage({ id: 'blog3.body' }),
            "image": "/images/blog2.webp",
            "readMoreLink": "#"
        }
    ];

    const accordionData = [
        {
            "sdAccordionID": "accordion-1",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq1.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq1.answer' }),
        },
        {
            "sdAccordionID": "accordion-2",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq2.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq2.answer' }),
        },
        {
            "sdAccordionID": "accordion-3",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq3.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq3.answer' }),
        },
        {
            "sdAccordionID": "accordion-4",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq4.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq4.answer' }),
        },
        {
            "sdAccordionID": "accordion-5",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq5.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq5.answer' }),
        },
        {
            "sdAccordionID": "accordion-6",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq6.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq6.answer' }),
        },
        {
            "sdAccordionID": "accordion-7",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq7.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq7.answer' }),
        },
        {
            "sdAccordionID": "accordion-8",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq8.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq8.answer' }),
        }
    ];

    return (
        <>
            <Header selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            <main>
                <section id="home" className="sd-section-hero">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="position-relative">
                                    <div className="sd-hero-content-wrapper d-flex flex-column justify-content-center position-relative">
                                        <h1 className="sd-section-hero-title">
                                            <FormattedMessage id="hero.title" />
                                        </h1>
                                        <p className="sd-section-hero-text">
                                            <FormattedMessage id="hero.text" />
                                        </p>
                                        <div>
                                            <button className="primary-btn" style={{ backgroundColor: "var(--sd-color-pink)" }} onClick={() => navigate('/try-search')}>
                                                <FormattedMessage id="hero.searchButton" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-md-block">
                                <div className="sd-hero-cover-placeholder position-relative">
                                    <div className="flex-center contain-image ratio ratio-1x1">
                                        <img src={slides[currentSlide].img} alt={slides[currentSlide].alt} className="w-100" style={{ maxHeight: '600px', height: 'auto', maxWidth: '600px', width: 'auto', paddingTop: '70px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="about" className="sd-section-about text-center">
                    <div className="container">
                        <div className="sd-about-content-wrapper d-flex flex-column">
                            <h2 className="sd-about-title">
                                <FormattedMessage id="about.title" />
                            </h2>
                            <p></p>
                            <h5 className="h5-mobile-responsive">
                                <FormattedMessage id="about.description" />
                            </h5>
                            <p></p>
                        </div>
                    </div>
                </section>

                <section id="how" className="sd-section-about text-center">
                    <div className="container">
                        <div className="sd-about-content-wrapper d-flex flex-column">
                            <h2 className="sd-about-title">
                                <FormattedMessage id="howItWorks.title" />
                            </h2>
                            <p></p>
                            <p></p>
                        </div>
                    </div>
                </section>

                <section id="feature" className="sd-section-feature">
                    <div className="container">
                        <div className="sd-feature-column d-flex flex-column">
                            <div className="sd-feature-row">
                                <div className="sd-feature-content d-flex flex-column">
                                    <h4 className="sd-feature-content-title">
                                        <FormattedMessage id="feature1.title" values={{ span: (chunks) => <span style={{ color: "var(--sd-color-pink)" }}>{chunks}</span> }} />
                                    </h4>
                                    <h6 className="sd-feature-content-text">
                                        <FormattedMessage id="feature1.text" />
                                    </h6>
                                </div>
                                <div className="sd-feature-cover ratio ratio-1x1 flex-center flex-auto-0 contain-image">
                                    <img
                                        alt="feature-01"
                                        src="/images/selfie.webp"
                                        style={{ borderRadius: '900px', maxWidth: '400px', maxHeight: '400px', width: "auto" }}
                                    />
                                </div>

                            </div>


                            {/* FEATURE 02 */}
                            <div className="sd-feature-row-reverse">
                                <div className="sd-feature-content d-flex flex-column">
                                    <h4 className="sd-feature-content-title">
                                        <FormattedMessage id="feature2.title" values={{ span: (chunks) => <span style={{ color: "var(--sd-color-pink)" }}>{chunks}</span> }} />
                                    </h4>
                                    <h6 className="sd-feature-content-text">
                                        <FormattedMessage id="feature2.text" />
                                    </h6>
                                </div>
                                <div className="sd-feature-cover ratio ratio-1x1 flex-center flex-auto-0 contain-image">
                                    <img
                                        alt="feature-02"
                                        src="/images/tech.webp"
                                        style={{ borderRadius: '900px', maxWidth: '400px', maxHeight: '400px', width: "auto" }}
                                    />
                                </div>
                            </div>

                            {/* FEATURE 03 */}
                            <div className="sd-feature-row">
                                <div className="sd-feature-content d-flex flex-column">
                                    <h4 className="sd-feature-content-title">
                                        <FormattedMessage id="feature3.title" values={{ span: (chunks) => <span style={{ color: "var(--sd-color-pink)" }}>{chunks}</span> }} />
                                    </h4>
                                    <h6 className="sd-feature-content-text">
                                        <FormattedMessage id="feature3.text" />
                                    </h6>
                                </div>
                                <div className="sd-feature-cover ratio ratio-1x1 flex-center flex-auto-0 contain-image">
                                    <img
                                        alt="feature-03"
                                        src="/images/face-scan.webp"
                                        style={{ borderRadius: '900px', maxWidth: '400px', maxHeight: '400px', width: "auto" }}
                                    />
                                </div>
                            </div>

                            {/* FEATURE 04 */}
                            <div className="sd-feature-row-reverse">
                                <div className="sd-feature-content d-flex flex-column">
                                    <h4 className="sd-feature-content-title">
                                        <FormattedMessage id="feature4.title" values={{ span: (chunks) => <span style={{ color: "var(--sd-color-pink)" }}>{chunks}</span> }} />
                                    </h4>
                                    <h6 className="sd-feature-content-text">
                                        <FormattedMessage id="feature4.text" />
                                    </h6>
                                </div>
                                <div className="sd-feature-cover ratio ratio-1x1 flex-center flex-auto-0 contain-image">
                                    <img
                                        alt="feature-04"
                                        src="/images/result.webp"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* _ENDS >> SECTION - FEATURE SECTION << WOS */}

                <section id="faq" className="sd-section-faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-md-flex align-items-center justify-content-center">
                                <h2 className="text-center mb-4">
                                    <FormattedMessage id="faq.title" />
                                </h2>
                            </div>
                            <div className="col-md-6">
                                <div className="sd-faq-accordion-container d-flex flex-column align-items-center justify-content-center flex-wrap">
                                    {accordionData.map((item, index) => {
                                        return (
                                            <Accordion key={index} className="sd-faq-accordion">
                                                <AccordionSummary
                                                    id={item.sdAccordionID}
                                                    className="sd-faq-accordion-header"
                                                    aria-controls="panel1-content"
                                                    expandIcon={
                                                        <div className="sd-faq-accordion-icon">
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                    }
                                                >
                                                    {item.sdAccordionQuestion}
                                                </AccordionSummary>
                                                <AccordionDetails className="sd-faq-accordion-content">
                                                    {item.sdAccordionAnswer}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="blogs">
                    <h2 className="text-center mb-4">
                        <FormattedMessage id="blogs.title" />
                    </h2>
                    <div className="sd-section-blogs d-flex justify-content-center align-items-center">
                        <Carousel>
                            {blogs.map((blog, index) => (
                                <Carousel.Item key={index} className="sd-blog-item">
                                    <img
                                        className="sd-blog-img img-fluid"
                                        src={blog.image}
                                        alt={"Slide " + (index + 1)}
                                    />
                                    <Carousel.Caption className="sd-blog-content">
                                        <h3>{blog.title}</h3>
                                        <p>{blog.snippet} <a onClick={() => openModal(blog)} className="read-more-link"><FormattedMessage id="blogs.readMore" /></a></p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    {showModal && (
                        <div className="blog-modal-overlay" onClick={closeModal}>
                            <div className="blog-modal-content" onClick={e => e.stopPropagation()}>
                                <button className="close-modal-btn" onClick={closeModal}>&times;</button>
                                <h2>{activeBlog.title}</h2>
                                <img src={activeBlog.image} alt="Blog Image" className="modal-blog-img" />
                                <div dangerouslySetInnerHTML={{ __html: activeBlog.body }}></div>
                            </div>
                        </div>
                    )}
                </section>

                <section id="motto" className="sd-section-motto">
                    <div className="container">
                        <div className="sd-motto-container">
                            <div className="sd-motto-content d-flex flex-column">
                                <h2 className="sd-motto-content-title">
                                    <FormattedMessage id="motto.title" />
                                </h2>
                                <p className="sd-motto-content-text">
                                    <FormattedMessage id="motto.text" />
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="about" className="sd-section-about text-center">
                    <div className="container">
                        <div className="sd-about-content-wrapper d-flex flex-column">
                            <h2 className="sd-about-title">
                                <FormattedMessage id="cta.title" />
                            </h2>
                            <p></p>
                            <p style={{ color: "black" }}>
                                <FormattedMessage id="cta.text" values={{ email: "hello@getzaya.app" }} />
                            </p>
                            <div>
                                <button className="primary-btn" style={{ backgroundColor: "var(--sd-color-pink)" }} onClick={() => navigate('/try-search')}>
                                    <FormattedMessage id="cta.searchButton" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <div className="container text-center" style={{ color: "black" }}>
                    <FormattedMessage id="footer.text" values={{ year: 2024, email: "hello@getzaya.app" }} />
                </div>
            </footer>
            {isFreeSearchModalOpen && (
                <FreeSearchModal
                    isOpen={isFreeSearchModalOpen}
                    onClose={() => setIsFreeSearchModalOpen(false)}
                />
            )}
        </>
    );
};

export default LandingPage;
