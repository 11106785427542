import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { ListItem, ListItemButton, ListItemText, IconButton } from "@mui/material";
import { routes } from "../routes";
import "./DashboardLayout.css";
import { useAuth } from "../AuthContext";
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormattedMessage } from 'react-intl';


const drawerWidth = 300;



export const DashboardLayout = ({ children, selectedLanguage, setSelectedLanguage }) => {
    const { currentUser, signOut } = useAuth();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const handleSignOut = async () => {
        try {
            await signOut();
            navigate("/");
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <div className="dashboard">
            {/* Hamburger icon */}
            <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, display: { md: 'block', lg: 'none' }, paddingLeft: '30px', paddingTop: '30px' }}
            >
                <MenuIcon />
            </IconButton>
            <div className="h1 flex-center sd-header-logo">
                <img src="/logo192.png" alt="Logo" style={{ verticalAlign: 'middle', marginRight: '10px', height: '50px' }} />
                <FormattedMessage id="appName" defaultMessage="FaceSearch App" />
            </div>
            <div className="d-flex">
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            width: drawerWidth,
                            boxSizing: "border-box",
                        },
                    }}
                    variant={isDesktop ? "permanent" : "temporary"}
                    open={open}
                    onClose={handleDrawerClose}
                    ModalProps={{ keepMounted: true }}
                    className="sidebar"
                >
                    <ListItem className="py-4 justify-content-center mb-2" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                        <div className="h1 flex-center text-center sd-header-logo">
                            <img src="/logo192.png" alt="Logo" style={{ verticalAlign: 'middle', marginRight: '10px', height: '50px' }} />
                            <FormattedMessage id="appName" defaultMessage="FaceSearch App" />
                        </div>
                    </ListItem>
                    {routes
                        .filter((r) => r.path !== "/")
                        .map((route) => (
                            <ListItem key={route.id} disablePadding>
                                <ListItemButton
                                    component={NavLink}
                                    to={route.path}
                                    sx={({ isActive }) => ({
                                        backgroundColor: isActive ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                    })}
                                >
                                    {route.name === "Search" && <SearchIcon sx={{ mr: 2 }} />}
                                    {route.name === "Search History" && <HistoryIcon sx={{ mr: 2 }} />}
                                    <ListItemText primary={<FormattedMessage id={`route.${route.name}`} defaultMessage={route.name} />} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    {currentUser && (
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleSignOut}>
                                <LogoutIcon sx={{ mr: 2 }} />
                                <ListItemText primary={<FormattedMessage id="signOut" defaultMessage="Sign Out" />} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {/* Close icon to hide menu */}
                    {isDesktop ? null : (
                        <IconButton
                            color="inherit"
                            aria-label="close drawer"
                            onClick={handleDrawerClose}
                            edge="start"
                            sx={{ ml: 2, position: "absolute", top: 20, right: 0 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </Drawer>
                <div className="main-content">{children}</div>
            </div>
        </div>
    );
};
