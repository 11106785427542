import { DashboardLayout } from "../components/DashboardLayout";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse, List, ListItem, ListItemIcon, ListItemText
} from "@mui/material";
import firebase from "../firebase";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function SearchHistory({ selectedLanguage, setSelectedLanguage }) {
    const [searchHistory, setSearchHistory] = useState([]);
    const [overlayData, setOverlayData] = useState([]);
    const [loadingStates, setLoadingStates] = useState({});
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { pathname } = useLocation();
    const [expandedDomains, setExpandedDomains] = useState([]);

    useEffect(() => {
        const loadSearchHistory = async () => {
            const user = firebase.auth().currentUser;
            if (!user) return;

            try {
                const querySnapshot = await firebase
                    .firestore()
                    .collection(`users/${user.uid}/searches`)
                    .where("result", "!=", 'pending')
                    .get();

                const history = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setSearchHistory(history);
            } catch (error) {
                console.error("Error fetching search history:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadSearchHistory();
    }, []);

    const fetchAndShowOverlayData = async (resultUrl, rowId) => {
        setLoadingStates((prevState) => ({ ...prevState, [rowId]: true }));

        try {
            const response = await fetch(resultUrl);
            if (!response.ok) throw new Error("Failed to fetch");
            const data = await response.json();
            setOverlayData(data);
            setIsOverlayOpen(true);
        } catch (error) {
            console.error("Error fetching JSON data:", error);
        } finally {
            setLoadingStates((prevState) => ({ ...prevState, [rowId]: false }));
        }
    };

    const getColorBasedOnScore = (score) => {
        if (score >= 90) return "#50C878";
        else if (score > 60) return "orange";
        else return "#FF5733";
    };

    const toggleDomain = (domain) => {
        if (expandedDomains.includes(domain)) {
            setExpandedDomains(expandedDomains.filter((d) => d !== domain));
        } else {
            setExpandedDomains([...expandedDomains, domain]);
        }
    };

    const groupedData = overlayData.reduce((acc, item) => {
        const domain = new URL(item.url).hostname;
        if (!acc[domain]) {
            acc[domain] = [];
        }
        acc[domain].push(item);
        return acc;
    }, {});

    const sortedDomains = Object.entries(groupedData).sort((a, b) => b[1].length - a[1].length);
    const topDomains = sortedDomains.slice(0, 5);

    const sortedImages = overlayData.sort((a, b) => b.score - a.score);

    return (
        <>
            <DashboardLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <h5 className="mb-5">
                    <FormattedMessage id="searchHistory.title" defaultMessage="Search History" />
                </h5>
                {isLoading ? (
                    <div>
                        <FormattedMessage
                            id="loading"
                            defaultMessage="Loading..."
                        />
                    </div>
                ) : (
                    <TableContainer component={Paper} sx={{ backgroundColor: "#272727", borderRadius: "12px", overflow: "auto" }}>
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: "var(--sd-color-hex-blue)" }}>
                                    <TableCell
                                        sx={{
                                            color: "var(--sd-color-hex-white)",
                                            fontWeight: "600",
                                            whiteSpace: "nowrap",
                                            padding: { xs: "8px", sm: "16px" },
                                        }}
                                    >
                                        <FormattedMessage id="searchHistory.dateTime" defaultMessage="Date/Time" />
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            color: "var(--sd-color-hex-white)",
                                            fontWeight: "600",
                                            whiteSpace: "nowrap",
                                            padding: { xs: "8px", sm: "16px" },
                                        }}
                                        align="center"
                                    >
                                        <FormattedMessage id="searchHistory.sourceImage" defaultMessage="Source Image" />
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            color: "var(--sd-color-hex-white)",
                                            fontWeight: "600",
                                            whiteSpace: "nowrap",
                                            padding: { xs: "8px", sm: "16px" },
                                        }}
                                        align="center"
                                    >
                                        <FormattedMessage id="searchHistory.searchResult" defaultMessage="Search Result" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ backgroundColor: "white" }}>
                                {searchHistory.map((row) => {
                                    return (
                                        row.result && (
                                            <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell sx={{ color: "var(--sd-color-hex-black)" }} component="th" scope="row">
                                                    {row.createdAt.toLocaleString()}
                                                </TableCell>
                                                <TableCell sx={{ color: "var(--sd-color-hex-black)" }} align="center">
                                                    <div className="sd-table-img-card overflow-hidden">
                                                        <img className="w-100 h-100 object-fit-cover" src={row.image} alt="search-history-source" />
                                                    </div>
                                                </TableCell>
                                                <TableCell sx={{ color: "var(--sd-color-hex-black)" }} align="center">
                                                    {row.result && (
                                                        <Button
                                                            variant="contained"
                                                            loading={loadingStates[row.id]}
                                                            onClick={() => fetchAndShowOverlayData(row.result, row.id)}
                                                            style={{
                                                                backgroundColor: "rgba(0,0,0,0)",
                                                                color: "var(--sd-color-hex-blue)",
                                                                textTransform: "none",
                                                                boxShadow: "none",
                                                                border: "1px solid var(--sd-color-hex-blue)",
                                                            }}
                                                        >
                                                            <FormattedMessage id="searchHistory.view" defaultMessage="View" />
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={isOverlayOpen}
                    onClose={() => setIsOverlayOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    classes={{
                        paper: "modalBg",
                    }}
                >
                    <DialogTitle className="d-flex align-items-center justify-content-between" style={{ backgroundColor: "var(--sd-color-hex-blue)", color: "white" }}>
                        <FormattedMessage id="searchHistory.title" defaultMessage="Search History" />
                        <IconButton onClick={() => setIsOverlayOpen(false)}>
                            <CloseIcon color="inherit" sx={{ color: "white" }} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ backgroundColor: "white" }}>
                        <div style={{ marginBottom: "20px" }}>
                            <p></p>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="interpretation-guide-content"
                                    id="interpretation-guide-header"
                                >
                                    <Typography variant="subtitle1" style={{ fontWeight: 600, color: "black" }}>
                                        <FormattedMessage
                                            id="search.result.guide"
                                            defaultMessage="⚠️ Interpretation Guide:"
                                        />
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body2" style={{ color: "black" }}>
                                        <FormattedMessage
                                            id="search.result.guideText"
                                            defaultMessage="We utilize advanced AI algorithms to search for potential matches of the uploaded image across various internet sources. While we strive for accuracy, we do not guarantee that a person is catfishing. In addition, it is important to note that there are people who look very similar to each other and in that case users must check the sources in-depth to decide for themselves whether they are indeed the same people or not. All results should be interpreted as informational only:"
                                        />
                                    </Typography>
                                    <ul style={{ marginLeft: "20px", fontSize: "14px", marginBottom: 0, color: "black" }}>
                                        <li>
                                            <strong>
                                                -
                                                <span style={{ fontWeight: 700, color: "#50C878" }}>
                                                    <FormattedMessage
                                                        id="search.result.highMatch"
                                                        defaultMessage="90%-100%"
                                                    />
                                                </span>
                                            </strong>
                                            :{" "}
                                            <FormattedMessage
                                                id="search.result.highMatchText"
                                                defaultMessage="Fairly confident match."
                                            />
                                        </li>
                                        <li>
                                            <strong>
                                                -
                                                <span style={{ fontWeight: 700, color: "orange" }}>
                                                    <FormattedMessage
                                                        id="search.result.mediumMatch"
                                                        defaultMessage="60-89%"
                                                    />
                                                </span>
                                            </strong>
                                            :{" "}
                                            <FormattedMessage
                                                id="search.result.mediumMatchText"
                                                defaultMessage="Somewhat certain; double-check recommended."
                                            />
                                        </li>
                                        <li>
                                            <strong>
                                                -
                                                <span style={{ fontWeight: 700, color: "#FF5733" }}>
                                                    <FormattedMessage
                                                        id="search.result.lowMatch"
                                                        defaultMessage="0-59%"
                                                    />
                                                </span>
                                            </strong>
                                            :{" "}
                                            <FormattedMessage
                                                id="search.result.lowMatchText"
                                                defaultMessage="Less likely to be the same person; consider photo quality and other factors."
                                            />
                                        </li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div>
                            <h5 style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <FormattedMessage
                                    id="search.resultBySources"
                                    defaultMessage="Result By Sources"
                                />
                            </h5>
                            {topDomains.map(([domain, items]) => (
                                <div key={domain}>
                                    <ListItem button onClick={() => toggleDomain(domain)}>
                                        <ListItemIcon>
                                            <img
                                                src={`https://www.google.com/s2/favicons?domain=${encodeURIComponent(domain)}`}
                                                alt="favicon"
                                                style={{ width: '24px', height: '24px' }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={domain}
                                            secondary={
                                                <FormattedMessage
                                                    id="search.occurrences"
                                                    defaultMessage="{count} occurrences"
                                                    values={{ count: Array.isArray(items) ? items.length : 0 }}
                                                />
                                            }
                                        />
                                        {expandedDomains.includes(domain) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </ListItem>
                                    <Collapse in={expandedDomains.includes(domain)} timeout="auto" unmountOnExit>
                                        <div style={{ display: 'flex', overflowX: 'auto' }}>
                                            {Array.isArray(items) && items.map((item, index) => (
                                                <div key={index} style={{ marginRight: '16px', flexShrink: 0, width: '200px' }}>
                                                    <div className="search-history-item-wrapper">
                                                        <div className="search-history-item-card" style={{ border: "1px solid var(--sd-color-hex-blue)" }}>
                                                            <div className="search-history-image-placeholder">
                                                                <div className="search-history-image ratio ratio-1x1 flex-center cover-image">
                                                                    <img alt="search-history-item" src={item.base64} />
                                                                </div>
                                                                <div
                                                                    className="search-history-stats"
                                                                    style={{ fontWeight: 700, color: getColorBasedOnScore(item.score) }}
                                                                >
                                                                    {item.score}%
                                                                </div>
                                                            </div>
                                                            <Button
                                                                size="large"
                                                                style={{ color: "var(--sd-color-hex-blue)", textTransform: "none", backgroundColor: "white", display: "flex", alignItems: "center" }}
                                                                component="a"
                                                                href={item.url}
                                                                target="_blank"
                                                            >
                                                                <FormattedMessage id="search.browse" defaultMessage="Browse" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                            ))}

                            <h5 style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <FormattedMessage
                                    id="search.resultByImages"
                                    defaultMessage="Result By Images"
                                />
                            </h5>
                            <Grid container spacing={2}>
                                {sortedImages.map((item, index) => (
                                    <Grid item xs={6} sm={6} md={4} lg={3} xl={2} gap={20} key={index}>
                                        <div className="search-history-item-wrapper">
                                            <div className="search-history-item-card" style={{ border: "1px solid var(--sd-color-hex-blue)" }}>
                                                <div className="search-history-image-placeholder">
                                                    <div className="search-history-image ratio ratio-1x1 flex-center cover-image">
                                                        <img alt="search-history-item-02" src={item.base64} />
                                                    </div>
                                                    <div
                                                        className="search-history-stats"
                                                        style={{ fontWeight: 700, color: getColorBasedOnScore(item.score) }}
                                                    >
                                                        {item.score}%
                                                    </div>
                                                </div>
                                                <Button
                                                    size="large"
                                                    style={{ color: "var(--sd-color-hex-blue)", textTransform: "none", backgroundColor: "white", display: "flex", alignItems: "center" }}
                                                    component="a"
                                                    href={item.url}
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={`https://www.google.com/s2/favicons?domain=${encodeURIComponent(item.url)}`}
                                                        alt="favicon"
                                                        style={{ width: "16px", height: "16px", marginRight: "8px" }}
                                                    />
                                                    <FormattedMessage id="search.browse" defaultMessage="Browse" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </DialogContent>
                </Dialog>
            </DashboardLayout>
        </>
    );
}