// facesearchBalanceHelper.js
import firebase from "../firebase";

export const validateFacesearchBalance = async (isFreemium = false) => {

    if (!isFreemium) {
        const user = firebase.auth().currentUser;
        if (!user) return false;
    }

    try {
        const balanceSnapshot = await firebase
            .firestore()
            .collection("facecheck")
            .doc("balance")
            .get();

        const remainingBalance = balanceSnapshot.data().remaining;
        return remainingBalance > 0;
    } catch (error) {
        console.error("Error fetching remaining balance:", error);
        return false;
    }
};