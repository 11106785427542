import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../AuthContext";
import MenuIcon from '@mui/icons-material/Menu';
import { FormattedMessage } from 'react-intl';

const languageOptions = [
    { flag: '🇺🇸', name: 'English', code: 'en' },
    { flag: '🇰🇷', name: 'Korean', code: 'ko' },
    { flag: '🇯🇵', name: 'Japanese', code: 'ja' },
    { flag: '🇮🇩', name: 'Indonesian', code: 'id' },
    { flag: '🇪🇸', name: 'Spanish', code: 'es' },
    { flag: '🇫🇷', name: 'French', code: 'fr' },
    { flag: '🇩🇪', name: 'German', code: 'de' },
    { flag: '🇲🇳', name: 'Mongolian', code: 'mn' },
    { flag: '🇨🇳', name: 'Chinese', code: 'zh' },
];

const Header = ({ selectedLanguage, setSelectedLanguage }) => {
    const { currentUser, handleSignInOrRegister } = useAuth();
    const [showMenu, setShowMenu] = useState(false);
    const isMobile = window.innerWidth <= 768;
    const headerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.innerWidth <= 768;
            setShowMenu(!isMobileView);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup function
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <header className={`${isMobile ? 'sticky-mobile' : ''}`} ref={headerRef}>
            <div className="container">
                <div className="sd-header-row">
                    {isMobile && (
                        <div className="sd-header-logo" onClick={toggleMenu}>
                            <MenuIcon style={{ color: 'black', fontSize: '30px' }} />
                        </div>
                    )}
                    {/* App logo and name, always visible on desktop screens */}
                    {!isMobile && (
                        <div className="h1 flex-center sd-header-logo">
                            <img src="/logo192.png" alt="Logo" style={{ verticalAlign: 'middle', marginRight: '10px', height: '50px' }} />
                            facesearch
                        </div>
                    )}
                    {/* Menu items */}
                    <div className={`sd-header-links-wrapper ${isMobile ? (showMenu ? 'show-menu' : '') : 'show-menu'}`}>
                        <nav className="sd-header-nav">
                            <ul className="sd-header-menu-list mb-0">
                                {/* List of menu items */}
                                <li className="sd-header-menu-item">
                                    <a className="sd-header-menu-link" href="#home">
                                        <FormattedMessage id="header.home" defaultMessage="Home" />
                                    </a>
                                </li>
                                <li className="sd-header-menu-item">
                                    <a className="sd-header-menu-link" href="#about">
                                        <FormattedMessage id="header.about" defaultMessage="About" />
                                    </a>
                                </li>
                                <li className="sd-header-menu-item">
                                    <a className="sd-header-menu-link" href="#how">
                                        <FormattedMessage id="header.how" defaultMessage="How It Works" />
                                    </a>
                                </li>
                                <li className="sd-header-menu-item">
                                    <a className="sd-header-menu-link" href="#faq">
                                        <FormattedMessage id="header.faq" defaultMessage="FAQ" />
                                    </a>
                                </li>
                                <li className="sd-header-menu-item">
                                    <a className="sd-header-menu-link" href="#blogs">
                                        <FormattedMessage id="header.blogs" defaultMessage="Blogs" />
                                    </a>
                                </li>
                                <li className="sd-header-menu-item">
                                    <div className="sd-header-menu-link">
                                        <select
                                            value={selectedLanguage}
                                            onChange={(e) => setSelectedLanguage(e.target.value)}
                                            style={{ borderRadius: '10px', borderColor: "white", fontSize: 'inherit', color: 'inherit' }}
                                        >
                                            {languageOptions.map(({ flag, name, code }) => (
                                                <option key={code} value={code}>
                                                    {flag} {name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
